import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoomService } from '../services/room.service'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  randRoom

  joinID = ""
  myName = ""

  


  constructor(
    public roomService: RoomService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.roomService.getLocalStorage()
    this.roomService.updateLocale()
    this.roomService.checkUserLocale()
    // window.location.href = 'sdf'
  }

  


  openName(){
    document.getElementById('inputName').classList.toggle('active')
    document.getElementById('roomBtn').classList.toggle('inactive')
  }


  createRoom() {
    if(this.roomService.player.name!=null){
      this.roomService.updateLocalStorage()
      this.roomService.createRoom()
    }
    
  }

  joinRoom(){
    if(this.joinID.length == 6){
      this.router.navigate(['/room/', this.joinID])
      // this.roomService.joinRoom(this.joinID)
    }
  }

  changeLocale(lang){
    this.roomService.changeLocaleTo(lang)
  }

  // getRoom(){
  //   this.roomService.getRandomRoom().then( res =>
  //     {console.log(res.toJSON())
  //       this.randRoom = res}
  //   )
  // }

}
