import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoomService } from '../services/room.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss']
})
export class RoomComponent implements OnInit {

  pointsView = false

  showAppear=false

  announce = false

  nameTaken = false

  addingGameScreen = false

  overviewScreen = false

  constructor(
    public roomService: RoomService,
    private activeRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.roomService.getLocalStorage()
    this.roomService.updateLocale()
    const roomID = this.activeRoute.snapshot.params.id
    this.roomService.roomID = roomID
    this.roomService.joinRoom(roomID)
    this.roomService.getOverviewDoc()
    // this.roomService.update.subscribe(()=>{
    //   this.cd.detectChanges()
    // })
  }


  noActivePlayer(){
    if(this.roomService.player.name == null || !this.roomService.theRoomWhereItHappens?.players[this.roomService.player.id]) 
      return true;
      else 
      return false;
  }

  

  joinGame(){
    if(this.roomService.player.name!=null || !this.roomService.theRoomWhereItHappens.players[this.roomService.player.id]){
      if(!this.roomService.playerList.find(player => player.name == this.roomService.player.name.trim())){
        this.roomService.addPlayerToRoom()
      } else {
        this.nameTaken = true
      }
      
    }
  }

  getDoubleDareText(){
    var body = this.roomService.currentGame.body[this.roomService.locale] || this.roomService.currentGame.body.en
    const regex1 = /##NAME1##/g
    const regex2 = /##NAME2##/g
    body = body.replace(regex1, this.roomService.theRoomWhereItHappens.players[this.roomService.theRoomWhereItHappens.player1].name)
    body = body.replace(regex2, this.roomService.theRoomWhereItHappens.players[this.roomService.theRoomWhereItHappens.player2].name)
    // console.log("after replace",body)
    return body
  }

  joinAsOverview(){
    this.overviewScreen = true
  }

  start(){
    this.roomService.setNextGame('dare')
  }

  next(){
    // this.roomService.setNextGame('dare')
    this.roomService.voteNext()
  }

  toggleScores(){
    if(this.pointsView || this.addingGameScreen){
      if(this.pointsView){
        this.pointsView = false
      }
      if(this.addingGameScreen){
        this.addingGameScreen = false
      }
    } else this.pointsView = true
    
    // if(!this.pointsView && !this.addingGameScreen){
      
    // }
    
  }

  inputClick(id){
    const input  = document.getElementById(id) as HTMLInputElement
    input.focus()
    input.select()
    document.execCommand("copy");
    this.openSnackBar('Copied to clipboard', '')
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  async test(){
    this.showAppear = true
    await this.roomService.delay(3500)
    this.announce = true
    await this.roomService.delay(1500)
    this.showAppear = true
    
  }

  addCustomGame(){
    this.addingGameScreen = true
    this.pointsView = false
  }



}
