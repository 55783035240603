<div class="wrapper bg">
    <div class="content">
        <div style="display: flex; justify-content: center;">
            <img class="top-img"  src="./assets/images/Cups.svg" alt="">
        </div>
        <div class="container">
            <h1 style="margin-bottom: -10px;" i18n>
                Welcome to
            </h1>
            <img class="logo" src="./assets/images/logo.svg" alt="drinkcracy">
            <h5 style="margin-top: -10px;" i18n>Made by players for players</h5>
            
            <button id="roomBtn" class="roomBtn" (click)="openName()" i18n>Create Room</button>
            <div id="inputName" class="inputName">
                <div><h3 i18n>What's your Name?</h3></div>
                <input type="text" [(ngModel)]="roomService.player.name" maxlength="10">
                <button style="margin-top: 20px;" (click)="createRoom()" i18n>Create room & go!</button>
            </div>
            <h2 i18n>Join Room</h2>
            <input [(ngModel)]="joinID" type="text">
            <button style="margin-top: 20px;" (click)="joinRoom()" i18n>Join Room</button>

            <br>
            <br>
            <div style="display: flex; justify-content: center;">
                <div class="language" *ngIf="roomService.locale!='en'" (click)="changeLocale('en')"><img style="height: 50px; width:50px;" src="./assets/images/languages/english.svg" alt=""></div>
                <div class="language" *ngIf="roomService.locale!='de'" (click)="changeLocale('de')"><img style="height: 50px; width:50px;" src="./assets/images/languages/german.svg" alt=""></div>
            </div>
            <!-- <div class="slidecontainer">
                <input type="range" min="1" max="100" value="50" class="slider" id="myRange">
            </div> -->
            <h1>The drinking game with a democratic element</h1>
            <h4>Collect points or drink</h4>
            <p>
                Drinkcracy is a drinking game for groups of at least three player (we recommend more). 
                Each player joins the room with their device and you're ready to go. During the game the group always decides the points or sips for refusal certain challenges are worth.
            </p>
            <h4>6 different categories</h4>
            <p>
               DRINKcracy features 6 different categories: truth, dare, double dare, punishment, council and challenge. 


            </p>
            <h4 style="margin-bottom: 0px;">
                  Truth / Dares
            </h4>
            <p style="margin-top: 0px;">
                Truth and dare are as commonly known except that the rest of the group can choose how many points or sips for refusal the truth/dare is worth. Then the player can choose to do it or to drink.
            </p>
            <h4 style="margin-bottom: 0px;">
                 Double Dares
            </h4>
            <p style="margin-top: 0px;">
                Double dares involve two players and both have the option to refuse to do it. But if the other one would have done it the person drinks double.
            </p>
            <h4 style="margin-bottom: 0px;">Council</h4>
            <p style="margin-top: 0px;">
                When the council is called, you have to choose one person in the group that fits best (Who's most likely to...). This person then either gets surprised by a reward or a punishment.
            </p>
            <h4 style="margin-bottom: 0px;">Challenge</h4>
            <p style="margin-top: 0px;">Challenge are for the whole group and determine a winner/loser. They are in the style of "first to ..." or "last to ..."</p>
            <h4 style="margin-bottom: 0px;">Punishment</h4>
            <p style="margin-top: 0px;">
                One player gets to punish another player.
            </p>
            <h1>DRINKcracy is for free & without ads</h1>
            <p>
                We made DRINKcracy for fun and we really hope you enjoy it. 
                We do not plan to limit functionalities in order to sell premium subscriptions. 
                We do not want to interupt the game flow with advertisement.
                We do not collect any personal information that we want to sell.
                This was made by people who like drinking games for people who like to play.
                BUT of course, having drinkcracy online entail cost for us (domain, hosting, database etc.). 
                Hence, if you enjoy the game consider supporting it by donating: <a href="https://www.buymeacoffee.com/drinkcracy">buymeacoffee.com/drinkcracy</a>
            </p>
            <p>
                
            </p>
        </div>
      
    </div>
</div>