import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class AddGamesService {

  newGames = []
  overviewDoc

  reset = { 
          dare: {
              easy: [],
              medium: [],
              hard: [],
              extreme: []
            },
            double_dare: {
              easy: [],
              medium: [],
              hard: [],
              extreme: []
            },
            truth: {
              easy: [],
              medium: [],
              hard: [],
              extreme: []
            },
            punishment: {
              easy: [],
              medium: [],
              hard: [],
              extreme: []
            },
            council: {
              easy: [],
              medium: [],
              hard: [],
              extreme: []
            },
            challenge: {
              easy: [],
              medium: [],
              hard: [],
              extreme: []
            }
        	}

  constructor(
    private store: AngularFirestore
  ) { }


  splitValues(string){
    console.log("splitting")
    var games = []
    const splitString = string.split(";")
    console.log(splitString)
    splitString.forEach(element => {
      const game = element.split("::")
      console.log(game)
      games.push({
        type: game[0],
        body: {
          en: game[1],
          de: game[2]
        },
        subtitle: {
          en: game[3],
          de: game[4]
        },
        extreme: game[5] * 1,
        points: game[6],
        sips: game[7]
      })
    });
    console.log(games)
    this.newGames = this.newGames.concat(games)
  }

  randomIdGenerator(){
    const chars = "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    let id = ""
    for(let i = 0; i < 8; i++){
      id += chars.substr(Math.floor(Math.random() * chars.length), 1)
    }
    console.log(id)
    return id
  }

  saveNewGames(){
    this.overviewDoc = this.reset
    this.newGames.forEach(element => {
      this.addGame(element)
    });
  }

  addGame(game){
    const randID = this.randomIdGenerator()
    var extreme 
    switch (game.extreme) {
      case 0: extreme = "easy"; break;
      case 1: extreme = "medium"; break;
      case 2: extreme = "hard"; break;
      case 3: extreme = "extreme"; break;
    }
    this.store.collection('games').doc(randID).set(game)
        .then(()=>{
          this.overviewDoc[game.type][extreme].push(randID)
        })
  }

  getOverview(){
    this.store.collection('overview').doc('overview001').get().toPromise().then(
      (overview) =>{
        this.overviewDoc = overview.data()
        console.log(this.overviewDoc)
      }
    )
  }

  updateOverview(){
    this.store.collection('overview').doc('overview001').update(
      this.overviewDoc
    )
  }

  // addFirstOverview(){
  //   this.store.collection('overview').doc('overview001').set({ 
  //       dare: {
  //           easy: [],
  //           medium: [],
  //           hard: [],
  //           extreme: []
  //         },
  //         double_dare: {
  //           easy: [],
  //           medium: [],
  //           hard: [],
  //           extreme: []
  //         },
  //         truth: {
  //           easy: [],
  //           medium: [],
  //           hard: [],
  //           extreme: []
  //         },
  //         punishment: {
  //           easy: [],
  //           medium: [],
  //           hard: [],
  //           extreme: []
  //         },
  //         council: {
  //           easy: [],
  //           medium: [],
  //           hard: [],
  //           extreme: []
  //         },
  //         challenge: {
  //           easy: [],
  //           medium: [],
  //           hard: [],
  //           extreme: []
  //         }
  //     	}
  //   )
  // }
}
