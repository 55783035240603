import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { RoomService } from '../services/room.service';

@Component({
  selector: 'app-add-custom',
  templateUrl: './add-custom.component.html',
  styleUrls: ['./add-custom.component.scss']
})
export class AddCustomComponent implements OnInit {

  typeSelected = 'truth'

  double_pre =''
  double_center=''
  double_post=''

  text
  textPost

  pointsAffected = 0

  constructor(
    private store: AngularFirestore,
    private roomService: RoomService
  ) { }

  ngOnInit(): void {
  }

  selectType(type){
    this.typeSelected = type
    this.reset()
  }

  lessPoints(yes){
    if(yes) this.pointsAffected--;
    else this.pointsAffected++;
    if(this.pointsAffected<-10) this.pointsAffected = -10
    if(this.pointsAffected>10) this.pointsAffected = 10
  }

  reset(){
    this.double_pre =''
    this.double_center=''
    this.double_post=''
    this.text = ''
    this.textPost =''
    this.pointsAffected = 0
  }

  randomIdGenerator(type){
    const chars = "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    let id = ""
    for(let i = 0; i < 6; i++){
      id += chars.substr(Math.floor(Math.random() * chars.length), 1)
    }
    var gameType
    switch (type) {
      case 'truth': gameType = 'tr'; break;
      case 'dare': gameType = 'da'; break;
      case 'double_dare': gameType = 'do'; break;
      case 'punishment': gameType = 'pu'; break;
      case 'council': gameType = 'co'; break;
      case 'challenge': gameType = 'ch'; break;
  
      default:
        break;
    }
    id = 'cus_'+ gameType +'_'+ id
    console.log(id)
    return id
  }

  submitCustom(){
    var custom
    switch (this.typeSelected) {
      case 'truth':{
        custom = {
          type: 'truth',
          body: {
            en: this.text
          },
          subtitle: {
            en: ''
          },
          extreme: -1,
          points: this.pointsAffected,
          sips: 0
        }
        break;
      }
      case 'dare':{
        custom = {
          type: 'dare',
          body: {
            en: this.text
          },
          subtitle: {
            en: ''
          },
          extreme: -1,
          points: this.pointsAffected,
          sips: 0
        }
        break;
      }
      case 'double_dare':{
        custom = {
          type: 'double_dare',
          body: {
            en: this.double_pre.trim()+' ##NAME1## '+this.double_center.trim()+' ##NAME2## '+this.double_post.trim()
          },
          subtitle: {
            en: ''
          },
          extreme: -1,
          points: this.pointsAffected,
          sips: 0
        }
        break;
      }
      case 'council':{
        custom = {
          type: 'council',
          body: {
            en: this.text
          },
          subtitle: {
            en: this.textPost
          },
          extreme: -1,
          points: this.pointsAffected,
          sips: 0
        }
        break;
      }
      case 'punishment':{
        custom = {
          type: 'punishment',
          body: {
            en: this.text
          },
          subtitle: {
            en: ''
          },
          extreme: -1,
          points: this.pointsAffected,
          sips: 0
        }
        break;
      }
      case 'challenge':{
        custom = {
          type: 'challenge',
          body: {
            en: this.text
          },
          subtitle: {
            en: ''
          },
          extreme: -1,
          points: this.pointsAffected,
          sips: 0
        }
        break;
      }
    
      default:
        break;
    }
    const custom_id = this.randomIdGenerator(this.typeSelected)
    this.store.collection('custom_games').doc(custom_id).set(custom)
    .then(()=>{
      this.roomService.addCustomGameId(custom_id)
    })
    this.reset()
  }

  testRandom(){
    const players = ['P1', 'P2', 'P3', 'P4', 'P5']
    var selection = []
    for(let i = 0; i < 100; i++){
      selection.push(players[Math.floor(Math.random() * players.length)])
    }
    var count={
      p1: 0,
      p2: 0,
      p3: 0,
      p4: 0,
      p5: 0
    }
    selection.forEach(player => {
      switch (player) {
        case 'P1': count.p1++; break;
        case 'P2': count.p2++; break;
        case 'P3': count.p3++; break;
        case 'P4': count.p4++; break;
        case 'P5': count.p5++; break;
      
        default:
          break;
      }
    })
    console.log(count)
  }

}
