import { JsonPipe } from '@angular/common';
import { localizedString, typeofExpr } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoomService {

  randomRoom
  randomRoom2

  theRoomWhereItHappens

  currentGame
  currentGameID = ""

  currentGameType = ""
  currentState = "awaiting_players"

  player = {
    name: null,
    id: null
  }

  myVote = 3
  myVote2 = 3
  voted = false
  decided= false

  roomID

  transactionSuccess

  playerList

  overviewDoc
  overviewDocCopy

  challengeDelay = true

  showOverlay = false
  announce = false

  locale = 'en'

  langCodes = {
    en: 'en-US',
    de: 'de'
  }

  public update: Subject<any> = new Subject<any>();

  public delay(ms: number) { return new Promise(resolve => setTimeout(resolve, ms)); }

  constructor(
   private db: AngularFireDatabase,
   private store: AngularFirestore,
   private router: Router
  ) { }

  createRoom(){
    const roomID =this.randomIdGenerator()
    var pre_play = {}
    pre_play[roomID+'01'] = {
      name: this.player.name,
      vote: false,
      online: false,
      points: 0
    }
    this.player.id = roomID+'01'
    this.db.database.ref('room/' + roomID).set(
      {
        players: pre_play,
        current_state: 'awaiting_players',
        current_game: '',
        rounds_played: 0,
        draw: false,
        council: '',
        challenge: false,
        rounds_to_play: 30
      }
    ).then( () => {
      // this.player.name = admin
      this.updateLocalStorage()
      this.router.navigate(['room/' + roomID])
      
    })
  }

  adjustRounds(){
    this.db.database.ref('room/'+ this.roomID + '/rounds_to_play').transaction((rounds) => {
      if(rounds){
        rounds++
      } 
      return rounds
    })
  }

  updateLocalStorage(){
    localStorage.setItem('drinkcracyPlayer', JSON.stringify(this.player))
  }

  getLocalStorage(){
    const localPlayer = localStorage.getItem('drinkcracyPlayer')
    if(localPlayer != null){
      this.player = JSON.parse(localPlayer)
    }
  }

  setOnline(){
    this.db.database.ref('room/'+ this.roomID + '/players/' + this.player.id ).update(
      {online: true}
    )
    this.playerDisconnect()
  }

  randomIdGenerator(){
    const chars = "1234567890abcde"
    let id = ""
    for(let i = 0; i < 6; i++){
      id += chars.substr(Math.floor(Math.random() * chars.length), 1)
    }
    console.log(id)
    return id
  }

  joinRoom(id){
    this.db.database.ref('room/' + id).on('value', (snapshot) => {
      if(snapshot.exists()){
        const data = snapshot.val();
        this.theRoomWhereItHappens = data
        this.updateData()
        console.log(data)
        console.log(JSON.stringify(data))
        this.update.next()
        // this.randomRoom = JSON.stringify(data)
      } else {
        console.log("ROOM DOES NOT EXIST")
      }
    })
  }

  addPlayerToRoom(){
    // this.db.database.ref('room/'+ this.roomID).update({
    //   players: 
    // })
    this.db.database.ref('room/'+ this.roomID + '/players').transaction((players) => {
      // players.push(this.player.name)
      // players.length
      var playerID
      if(Object.keys(players).length.toString().length == 1){
        playerID = this.roomID + "0" + (Object.keys(players).length + 1)
      } else playerID = this.roomID + (Object.keys(players).length + 1);
      players[playerID] = {
        name: this.player.name.trim(),
        vote: false,
        online: true,
        points: 0
      }
      this.player.id = playerID
      this.updateLocalStorage()
      return players
    })
    this.playerDisconnect()
  }

  playerDisconnect(){
    this.db.database.ref('room/'+ this.roomID + '/players/' + this.player.id).onDisconnect().update(
      {online: false}
    )
  }

  updateData(){
    this.playerList = []
    const keys = Object.keys(this.theRoomWhereItHappens.players) 
    // this.playerList = 
    // console.log(this.playerList)
    // const keys = Object.values(this.theRoomWhereItHappens.players) 
    keys.forEach(key => {
      this.playerList.push(this.theRoomWhereItHappens.players[key])
      this.playerList[this.playerList.length-1]['id'] = key
    })
    
    this.currentGameType = this.theRoomWhereItHappens.game_type
    if(this.currentGameID != this.theRoomWhereItHappens.current_game){
      this.currentGameID = this.theRoomWhereItHappens.current_game
      if(this.currentGameID.length == 8 || (this.currentGameID.startsWith('cus') && this.currentGameID.length == 13)){
        this.getGame(this.currentGameID)
      }
    }
    this.setViews()
    this.currentGameID
    console.log(keys, this.player.id, keys.includes(this.player.id), this.playerList)
    if(keys.includes(this.player.id) && !this.theRoomWhereItHappens.players[this.player.id].online){
      this.setOnline()
    }
  }

  async setViews(){
    console.log('setView -02',this.currentState, this.theRoomWhereItHappens.current_state, this.currentGameType)
    if(this.theRoomWhereItHappens.current_state != this.currentState) {
      if(this.currentState == 'input' && (this.currentGameType == 'dare' || this.currentGameType == 'double_dare' || this.currentGameType == 'truth')){
        console.log('delay after input')
        await this.delay(1500)
        this.currentState = this.theRoomWhereItHappens.current_state
      } else if(this.currentState == 'input' && this.currentGameType == 'council'){
        console.log('delay after council -collio')
        await this.delay(2000)
        this.currentState = this.theRoomWhereItHappens.current_state
      } else if(this.theRoomWhereItHappens.current_state == 'input') {
        this.showOverlay = true
        await this.delay(3500)
        this.announce = true
        await this.delay(1500)
        this.showOverlay = false
        this.announce = false
        this.currentState = this.theRoomWhereItHappens.current_state;
      } else {
        this.currentState = this.theRoomWhereItHappens.current_state;
      }
    }
  }

  selectRandomTypeDifficulty(){
    var difficulty
    var diff_selection
    if(this.theRoomWhereItHappens.rounds_played>50){
      diff_selection = [0,1,2,2,2,3,3,3]
    } else {
      if(this.theRoomWhereItHappens.rounds_played>30){
        diff_selection = [0,1,1,2,2,2,3,3,3]
      } else {
        if(this.theRoomWhereItHappens.rounds_played>20){
          diff_selection = [0,1,1,2,2,2,3,3]
        } else {
          if(this.theRoomWhereItHappens.rounds_played>10){
            diff_selection = [0,0,1,1,1,2,2,3,]
          } else {
            diff_selection = [0,0,0,0,1,1,2]
          }
        }
      }
    }
    switch (diff_selection[Math.floor(Math.random() * diff_selection.length)]) {
      case 0: difficulty = "easy"; break;
      case 1: difficulty = "medium"; break;
      case 2: difficulty = "hard"; break;
      case 3: difficulty = "extreme"; break;
    }
    const rand = Math.floor(Math.random() * 16)
    var type
    //Truth 4, Dare 4, Double Dare 3, Punishment 3, Challenge 2, Council 4
    if(rand < 4){
      // Truth 0 1 2 3
      type = 'truth'
    }
    if(rand < 8 && rand > 3){
      // Dare 4 5 6 7
      type = 'dare'
    }
    if(rand < 11 && rand > 7){
      // DD 8 9 10 
      type = 'double_dare'
    }
    if(rand < 13 && rand > 10){
      // punish 11 12 
      type = 'punishment'
    }
    if(rand < 14 && rand > 12){
      // challenge  13
      type = 'challenge'
    }
    if(rand < 16 && rand > 13){
      // council 14 15
      type = 'council'
    }
    return {type: type, difficulty: difficulty}
  }

  getRandomGame(){
    var nextGame
    var selection = {type: null, difficulty: null}
    const getCustomGame = Math.random()
    if(this.theRoomWhereItHappens.custom_games && this.theRoomWhereItHappens.custom_games instanceof Array && this.theRoomWhereItHappens.custom_games.length > 0 && getCustomGame>0.75){
      nextGame = this.theRoomWhereItHappens.custom_games[Math.floor(Math.random() * this.theRoomWhereItHappens.custom_games.length)]
      const parts = nextGame.split('_')
      switch (parts[1]) {
        case 'tr': selection.type = 'truth'; break;
        case 'da': selection.type = 'dare'; break;
        case 'do': selection.type = 'double_dare'; break;
        case 'co': selection.type = 'council'; break;
        case 'ch': selection.type = 'challenge'; break;
        case 'pu': selection.type = 'punishment'; break;
        default: break;
      }
    } else {
      selection = this.selectRandomTypeDifficulty()
      const gameArray = this.overviewDoc[selection.type][selection.difficulty]
      const randomIndex = Math.floor(Math.random() * gameArray.length)
      nextGame = gameArray[randomIndex]
      console.log("random game", nextGame, randomIndex, gameArray)
    }
    
    return {id: nextGame, type: selection.type}
  }

  async getGame(game_id){
    this.voted = false
    this.decided = false
    this.challengeDelay = true
    if(game_id.startsWith('cus')){
      await this.store.collection('custom_games').doc(game_id).get().toPromise().then(
        (game) =>{
          this.currentGame = game.data()
          console.log('getting custom gane')
          if(this.currentGame.type == 'challenge'){
            this.challengeDelayer()
          }
        }
      )
    } else {
      await this.store.collection('games').doc(game_id).get().toPromise().then(
        (game) =>{
          this.currentGame = game.data()
          if(this.currentGame.type == 'challenge'){
            this.challengeDelayer()
          }
        }
      )
      console.log(" game", this.currentGame)
      var difficulty
      switch (this.currentGame.extreme) {
        case 0: difficulty = "easy"; break;
        case 1: difficulty = "medium"; break;
        case 2: difficulty = "hard"; break;
        case 3: difficulty = "extreme"; break;
      }
      this.overviewDoc[this.currentGame.type][difficulty] = this.overviewDoc[this.currentGame.type][difficulty].filter(val => val != game_id)
      if(this.overviewDoc[this.currentGame.type][difficulty].length < 2){
        this.overviewDoc[this.currentGame.type][difficulty] = JSON.parse(JSON.stringify(this.overviewDocCopy[this.currentGame.type][difficulty]))
      }
    }
    return
    //If GAMES TO FEW REFILL FROM COPY
  }

  async challengeDelayer(){
    await this.delay(1500)
    this.challengeDelay = false
  }

  async voteNext(){
    var curRoom
    await this.db.database.ref('room/' + this.roomID).transaction((room) =>{
      if(room){
        room.players[this.player.id].next = true
        curRoom = room
      }
      return room
    })
    var countNext = 0
    const playerIDs = Object.keys(curRoom.players)
          playerIDs.forEach(id=>{
            if(curRoom.players[id].next) countNext++
          })
    if((countNext/playerIDs.length)>0.75){
      this.setNextGame('dare')
    }
  }

  async setNextGame(type){
    await this.db.database.ref('room/' + this.roomID).transaction((room) =>{
      if(room){
        if(room.current_state != "spinning"){
          room.current_state = "spinning"
          room.next_game = type
          const playerIDs = Object.keys(room.players)
          playerIDs.forEach(id=>{
            room.players[id].vote = false
            room.players[id].next = false
          })
          room.player1_decision = false
          room.player2_decision = false
          room.council = false
          room.draw = false,
          room.challenge = false
          room.punish = false,
          room.rounds_played += 1
          this.transactionSuccess = true
        } else {
          this.transactionSuccess = false
        }
      }
      return room
    })
    // if(this.transactionSuccess==null){
    //   console.log("Await fails")
    // }
    if(this.transactionSuccess){
      const randGame = this.getRandomGame()
      console.log('random game type', randGame)
      const players = this.setGame(randGame.type)
      var update = {
        current_state: 'input',
        player1: players.player1,
        player2: players.player2,
        current_game: randGame.id,
        game_type: randGame.type,
      }
      if(randGame.id.startsWith('cus')){
        update['custom_games'] = this.theRoomWhereItHappens.custom_games = this.theRoomWhereItHappens.custom_games.filter(val => val != randGame.id)
      }
      
      if(!update.player1){
        delete update.player1
      }
      if(!update.player2){
        delete update.player2
      }
      this.db.database.ref('room/'+ this.roomID).update(update)
      // this.db.database.ref('room/'+ this.roomID).update({
      //   current_game: randGame.id,
      //   game_type: randGame.type,
      //   current_state: 'setting'
      // })
      await this.getGame(randGame.id)
      // this.setGame()
    }
  }

  setGame(type){
    var players = JSON.parse(JSON.stringify(Object.keys(this.theRoomWhereItHappens.players)))
    var player1
    var player2
    switch (type) {
      case 'truth': {
        player1 = players[Math.floor(Math.random() * players.length)]
        break;
      }
      case 'dare': {
        player1 = players[Math.floor(Math.random() * players.length)]
        break;
      }
      case 'double_dare': {
        player1 = players[Math.floor(Math.random() * players.length)]
        do{
          player2 = players[Math.floor(Math.random() * players.length)]
        } while (player2 == player1)
        break;
      }
      case 'council': {
        break;
      }
      case 'punishment': {
        player1 = players[Math.floor(Math.random() * players.length)]
        break;
      }
      case 'challenge': {
        break;
      }
    }
    var update = {
      player1: player1,
      player2: player2,
    }
    return update
    // if(!update.player1){
    //   delete update.player1
    // }
    // if(!update.player2){
    //   delete update.player2
    // }
    
    // this.db.database.ref('room/'+ this.roomID).update(update)
  }

  submitMyVote(vote){
    this.voted = true
    this.db.database.ref('room/' + this.roomID).transaction((room) =>{
      if(room){
        room.players[this.player.id].vote = vote
        const players = Object.keys(room.players)
        var stillWaiting = false
        
        players.forEach(player =>{
          if((((room.game_type == 'dare' || room.game_type == 'truth') && player != room.player1) || (room.game_type == 'double_dare' && (player != room.player1 && player != room.player2))) && !room.players[player].vote && room.players[player].online){
            stillWaiting = true
          }
        })
        if(!stillWaiting){
          room.current_state = "decision"
        }
        // if(room.current_state != "spinning"){
        //   room.current_state = "spinning"
        //   room.next_game = type
        //   this.transactionSuccess = true
        // } else {
        //   this.transactionSuccess = false
        // }
      }
      return room
    })
  }

  getOverviewDoc(){
    this.store.collection('overview').doc('overview001').get().toPromise().then(
      (overview) =>{
        this.overviewDoc = overview.data()
        this.overviewDocCopy = JSON.parse(JSON.stringify(this.overviewDoc))
      }
    )
  }


  getRandomRoom(){
    const randomIndex = Math.floor(Math.random() * 6)
    console.log(randomIndex)
    this.db.database.ref('room/b47796').on('value', (snapshot) => {
      const data = snapshot.val();
      console.log(data)
      this.randomRoom = JSON.stringify(data)
    })
    this.db.database.ref('room/b47796').once('value', (snapshot) => {
      const data = snapshot.val();
      console.log(data)
      this.randomRoom2 = JSON.stringify(data)
    })
    // return this.db.database.ref('room').startAt(randomIndex).limitToFirst(1).get()
    // return this.db.database.ref('room').limitToFirst(randomIndex).limitToLast(1).get()
  }

  toDoOrNot(decision){
    var points = 0
    if(decision == 'do'){
      points = this.getWorth('points')
    }
    this.db.database.ref('room/'+ this.roomID).transaction((room) => {
      if(room){
        room.player1_decision = decision
        room.players[this.player.id].points += points
        room.current_state = 'action'
      }
      return room
    })
    // this.db.database.ref('room/'+ this.roomID).update({
    //   player1_decision: decision,
    //   players[this.]
    //   current_state: 'action'
    // })
    
  }

  toDoOrNot2(decision, player_num){
    this.decided = true
    this.db.database.ref('room/'+ this.roomID).transaction((room) => {
      if(room){
        room['player'+player_num+'_decision'] = decision
        if(room.player1_decision && room.player2_decision){
          if(room.player1_decision == 'do' && room.player2_decision == 'do'){
            room.players[room.player1].points += this.getWorth2('points', 1)
            room.players[room.player2].points += this.getWorth2('points', 2)
          }
          if(room.player1_decision == 'do' && room.player2_decision == 'not'){
            room.players[room.player1].points += this.getWorth2('points', 1) 
          }
          if(room.player1_decision == 'not' && room.player2_decision == 'do'){
            room.players[room.player2].points += this.getWorth2('points', 2)
          }
          if(room.player1_decision == 'not' && room.player2_decision == 'not'){
            
          }
          room.current_state = 'action'
        }
        
      }
      return room
    })
    // this.db.database.ref('room/'+ this.roomID).update({
    //   player1_decision: decision,
      
    // })
  }


  getWorth(kind){
    var count = 0
    var total = 0
    this.playerList.forEach(player => {
      if(player.vote){
        count++
        total += player.vote
      }
    });
    const average = Math.ceil(total/count)
    switch (kind) {
      case 'points': return average;
      case 'sips': return average*2;
      default: return null;

    }
  }

  getWorth2(kind: string, player_num: number){
    var count = 0
    var total = 0
    this.playerList.forEach(player => {
      if(player.vote){
        count++
        total += player.vote['vote'+player_num]
      }
    });
    const average = Math.ceil(total/count)
    switch (kind) {
      case 'points': return average;
      case 'sips': return average*2;
      default: return null;

    }
  }

  voteOnPlayer(player_id){
    this.voted = true
    this.db.database.ref('room/' + this.roomID).transaction((room) =>{
      if(room){
        room.players[this.player.id].vote = player_id
        const players = Object.keys(room.players)
        var stillWaiting = false
        
        players.forEach(player =>{
          if(!room.players[player].vote && room.players[player].online){
            stillWaiting = true
          }
        })
        if(!stillWaiting){
          var countObject = {}
          players.forEach(player =>{
            countObject[player] = 0
          })
          players.forEach(player =>{
            if(room.players[player].vote && room.players[player].online){
              countObject[room.players[player].vote]++
            }
          })
          const votes: Array<any> = Object.values(countObject)
          var top_votes = Math.max(...votes)
          players.forEach(player =>{
            if(countObject[player]!=top_votes){
              delete countObject[player]
            }
          })
          const top_players = Object.keys(countObject)
          if(top_players.length > 1){
            room.draw = top_players
            room.council = top_players[Math.floor(Math.random()*top_players.length)]
          } else {
            room.council = top_players[0]
            room.draw = false
          }
          room.players[room.council].points += this.currentGame.points*1
          room.current_state = "decision"
        }
      }
      return room
    })
  }

  challengeComplete(player_id){
    this.db.database.ref('room/' + this.roomID).transaction((room) =>{
      if(room){
        if(!room.challenge){
          room.challenge = player_id
          room.players[player_id].points += this.currentGame.points*1
          room.current_state = "decision"
        }
      }
      return room
    })
  }

  punishPlayer(player_id){
    this.db.database.ref('room/' + this.roomID).transaction((room) =>{
      if(room){
        room.punish = player_id
        room.players[player_id].points += this.currentGame.points*1
        room.current_state = "decision"
      }
      return room
    })
  }

  updateLocale(){
    const languages = {
      'en-US': 'en',
      de: 'de'
    }
    const lang = this.router.url.split('/')[0]
    const lang2 = window.location.href.split('/')

    // console.log( this.router.url, window.location.href)
    lang2.forEach(lan =>{
      if(languages[lan]){
        this.locale = languages[lan]
      }
    })
    console.log("the locale is set to", this.locale)
  }

  checkUserLocale(){
    const userLang = navigator.language.split('-')[0]
    console.log('navigator', navigator.language, userLang)
    const setLang = localStorage.getItem('drcy_lang')
    if(!window.location.href.includes('localhost')){
      if(setLang){
        if(!window.location.href.includes(this.langCodes[setLang])){
          var url = window.location.href.split('/')
          url[3] = this.langCodes[setLang]
          window.location.href = url.join('/')
        }
      } else if(userLang!='en' && !window.location.href.includes(this.langCodes[userLang])){
        if(this.langCodes[userLang]){
          var url = window.location.href.split('/')
          url[3] = this.langCodes[userLang]
          window.location.href = url.join('/')
        }
      }
    }
  }

  changeLocaleTo(lang){
    localStorage.setItem('drcy_lang', lang)
    var url = window.location.href.split('/')
    url[3] = this.langCodes[lang]
    window.location.href = url.join('/')
  }

  addCustomGameId(custom_id){
    this.db.database.ref('room/' + this.roomID).transaction((room) =>{
      if(room){
        if(room.custom_games && room.custom_games instanceof Array){
          room.custom_games.push(custom_id)
        } else {
          room.custom_games = []
          room.custom_games.push(custom_id)
        }
      }
      return room
    })
  }
    

}
