import { Component, OnInit } from '@angular/core';
import { AddGamesService } from '../services/add-games.service'

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {

  inputType
  inputString

  constructor(
    public addGamesService: AddGamesService  
  ) { }

  ngOnInit(): void {
    this.addGamesService.getOverview()
  }

  parseStringToGames(){
    this.addGamesService.splitValues(this.inputString)
  }

}
