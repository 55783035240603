<!-- <div class="wrapper bg room">
    <div class="content" style="position: relative; overflow-y: auto;">
        <img style="width: 200px;" src="./assets/images/logo.svg" alt="drinkcracy"> -->
        <!-- <button (click)="testRandom()">test</button> -->
        <div>
            <h2 style="margin-bottom: 10px;">Add custom game</h2>
            <div class="selectWrap">
                <div class="typeSelector" [ngClass]="{'selected': typeSelected=='truth'}" (click)="selectType('truth')">
                    Truth
                </div>
                <div class="typeSelector" [ngClass]="{'selected': typeSelected=='dare'}" (click)="selectType('dare')">
                    Dare
                </div>
                <div class="typeSelector" [ngClass]="{'selected': typeSelected=='double_dare'}" (click)="selectType('double_dare')">
                    Double Dare
                </div>
                <div class="typeSelector" [ngClass]="{'selected': typeSelected=='punishment'}" (click)="selectType('punishment')">
                    Punishment
                </div>
                <div class="typeSelector" [ngClass]="{'selected': typeSelected=='council'}" (click)="selectType('council')">
                    Council
                </div>
                <div class="typeSelector" [ngClass]="{'selected': typeSelected=='challenge'}" (click)="selectType('challenge')">
                    Challenge
                </div>
            </div>
            <div *ngIf="typeSelected == 'truth'">
                <h3>What is your custom truth question?</h3>
                <input class="full-input" [(ngModel)]="text" type="text" name="" id="" placeholder="Eg: When was the last time you wet your bed?">
                <button class="submit" (click)="submitCustom()">Submit</button>
            </div>
            <div *ngIf="typeSelected == 'dare'">
                <h3>What is your custom dare?</h3>
                <input class="full-input" [(ngModel)]="text" type="text" name="" id="" placeholder="Eg: Eat a raw egg">
                <button class="submit" (click)="submitCustom()">Submit</button>
            </div>
            <div *ngIf="typeSelected == 'double_dare'">
                <h3 style="margin-bottom: 5px;">What is your custom double dare?</h3>
                <div style="margin-bottom: 15px;">You can use all three fields to construct the double dare. [PlayerX] & [PlayerY] will be replaced by random players of your room. Below you see your dare as an example.</div>
                <div>
                    <input [(ngModel)]="double_pre" type="text" name="" id=""> [PlayerX] <input [(ngModel)]="double_center" type="text" name="" id=""> [PlayerY] <input [(ngModel)]="double_post" type="text" name="" id="">
                </div>
                <h4 *ngIf="double_pre || double_post || double_center">{{double_pre}} Max {{double_center}} Maxine {{double_post}}</h4>
                <button class="submit" (click)="submitCustom()">Submit</button>
            </div>
            <div *ngIf="typeSelected == 'punishment'">
                <h3>What is your custom punishment?</h3>
                <input class="full-input" [(ngModel)]="text" type="text" name="" id="" placeholder="Eg: Give out 5 sips">
                <br>
                <div style="margin-top: 15px;">Affects points of player:</div>
                <div class="points">
                    <div class="less" (click)="lessPoints(true)">
                        -
                    </div>
                    <div class="point">
                        {{pointsAffected}}
                    </div>
                    <div class="more" (click)="lessPoints(false)">
                        +
                    </div>
                </div>
                <button class="submit" (click)="submitCustom()">Submit</button>
            </div>
            <div *ngIf="typeSelected == 'council'">
                What is your custom question to the council?
                <input class="full-input" [(ngModel)]="text" type="text" name="" id="" placeholder="Eg: Who is most likely to become president?">
                <br>
                After the decision text: <br>
                <input class="full-input" [(ngModel)]="textPost" type="text" name="" id="" placeholder="Eg: Probably by bein corrupt. You lose 5 points">
                <br>
                Affects points of player:
                <div class="points">
                    <div class="less" (click)="lessPoints(true)">
                        -
                    </div>
                    <div class="point">
                        {{pointsAffected}}
                    </div>
                    <div class="more" (click)="lessPoints(false)">
                        +
                    </div>
                </div>
                <button class="submit" (click)="submitCustom()">Submit</button>
            </div>
            <div *ngIf="typeSelected == 'challenge'">
                What is your custom challenge?
                <input class="full-input" [(ngModel)]="text" type="text" name="" id="" placeholder="E.g: First person to down their drink gets 5 points.">
                <br>
                <div style="margin-top: 15px;">Affects points of player:</div>
                <div class="points">
                    <div class="less" (click)="lessPoints(true)">
                        -
                    </div>
                    <div class="point">
                        {{pointsAffected}}
                    </div>
                    <div class="more" (click)="lessPoints(false)">
                        +
                    </div>
                </div>
                <button class="submit" (click)="submitCustom()">Submit</button>
            </div>

        </div>
    <!-- </div>
</div> -->