<div class="wrapper bg room">
    <div class="content" style="position: relative; overflow-y: auto;">
        <img style="width: 200px;" src="./assets/images/logo.svg" alt="drinkcracy">
        <div  (click)="toggleScores()" class="scoresButton" *ngIf="!overviewScreen">
            <div *ngIf="!pointsView && !addingGameScreen" style="display: flex; align-items: center;">
                <img style="height: 30px; margin-right: 8px;" src="./assets/images/leaderboard.svg" alt="">
                <span style="font-size: 1.3rem; font-weight: 600;" i18n> Scores</span>
            </div>
            <div *ngIf="pointsView" style="display: flex; align-items: center;">
                <img style="height: 30px; margin-right: 8px;" src="./assets/images/casino.svg" alt="">
                <span style="font-size: 1.3rem; font-weight: 600;" i18n> Game</span>
            </div>
            <div *ngIf="addingGameScreen" style="display: flex; align-items: center;">
                <img style="height: 30px; margin-right: 8px;" src="./assets/images/casino.svg" alt="">
                <span style="font-size: 1.3rem; font-weight: 600;" i18n> Back to the game</span>
            </div>
        </div>
        <!-- <div >

        </div> -->
        <div style="display: flex; align-items: center; justify-content: center; min-height: 60%; flex-wrap: wrap;">
            <div *ngIf="!pointsView && !addingGameScreen">
                <div *ngIf="!overviewScreen && (roomService.theRoomWhereItHappens?.current_state == 'awaiting_players' || noActivePlayer())">
                    <h1 style="margin-bottom: 0px;" i18n>Hi {{roomService.player.name}}</h1>
                    <h3 style="margin-top: 0px;" i18n>waiting on other players</h3>
                    <!-- <h2 style="margin-bottom: 0px;">Room Number: </h2>
                    <input type="text" readonly [value]="roomService.roomID" name="" id="">
                    <h2 style="margin-bottom: 0px;">Invite link: </h2>
                    <input type="text" readonly [value]="'https://drinkcracy.com/room/'+roomService.roomID" name="" id=""> -->
                    <div *ngIf="noActivePlayer()">
                        <h2 i18n>What's your name?</h2>
                        <input type="text" [(ngModel)]="roomService.player.name" name="" id="">
                        <div *ngIf="nameTaken" style="margin-top: 5px; color: #f42f2f;" i18n>This name is already taken</div>
                        <button style="margin-top: 15px;" (click)="joinGame()" i18n>Join the game!</button>
                    </div>
                    <h2 i18n>Players in the room:</h2>
                    <div class="playerWrapper">
                        <div class="player" *ngFor="let player of roomService.playerList">
                            {{player.name}}
                        </div>
                    </div>
                    <div *ngIf="!noActivePlayer() && roomService.playerList.length<3" i18n>
                        You'll need at least 3 players!
                    </div>
                    <div>
                        How many rounds do you want to play?
                        <div>
                            <div class="points">
                                <div class="less" (click)="lessRounds(true)">
                                    -
                                </div>
                                <div class="point">
                                    {{roomService.theRoomWhereItHappens?.rounds_to_play}}
                                </div>
                                <div class="more" (click)="lessRounds(false)">
                                    +
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!noActivePlayer() && roomService.playerList.length>2">
                        <button style="margin-top: 50px;" (click)="start()" i18n>Start the game!</button>
                    </div>
                    <div style="margin-top: 45px;">
                        <button (click)="joinAsOverview()">Add this device as an overview screen!</button>
                    </div>
                    <h1 i18n>Invite Info</h1>
                    <h2 style="margin-bottom: 0px;" i18n>Room Number: </h2>
                    <input id="code" (click)="inputClick('code')" type="text" readonly [value]="roomService.roomID"
                        name="">
                    <h2 style="margin-bottom: 0px;" i18n>Invite link: </h2>
                    <input id="url" (click)="inputClick('url')" type="text" readonly
                        [value]="'https://drinkcracy.com/' + roomService.langCodes[roomService.locale] + '/room/'+roomService.roomID" name="">

                </div>
                <div *ngIf="roomService.currentGame && (!noActivePlayer() || overviewScreen)">
                    <div *ngIf="roomService.currentGame.type =='truth' || roomService.currentGame.type =='dare'">
                        <h1>{{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player1].name}}:
                            {{roomService.currentGame.body[roomService.locale] ? roomService.currentGame.body[roomService.locale] : roomService.currentGame.body.en}}</h1>
                        <div *ngIf="roomService.currentState == 'input'">
                            <div *ngIf="roomService.player.id == roomService.theRoomWhereItHappens.player1">
                                <h3 i18n="@@they-decide">They are deciding how many points or sips this is worth:</h3>
                                <div *ngFor="let player of roomService.playerList">
                                    <div *ngIf="player.id != roomService.player.id">
                                        <span style="font-weight: 700;">
                                            {{player.name}}:
                                        </span>
                                        <span *ngIf="player.vote" i18n>
                                            <span style="color: #4382f0; ">{{player.vote}} </span>
                                            points or <span style="color: #f42f2f; ">{{player.vote * 2}}
                                            </span>sips</span>
                                    </div>
                                    <!-- {{player.name}}: <span *ngIf="player.vote">{{player.vote}} points or {{player.vote * 2}} sips</span> -->
                                    <!-- {{roomService.theRoomWhereItHappens.players[roomService.player.id].voted}} -->
                                </div>
                            </div>
                            <div *ngIf="roomService.player.id != roomService.theRoomWhereItHappens.player1">
                                <h3 i18n>Select how much point or sips this is worth</h3>

                                <div *ngIf="!roomService.voted && !overviewScreen" class="slidecontainer">
                                    <input [(ngModel)]="roomService.myVote" type="range" min="1" max="10" value="5"
                                        step="1" class="slider" id="myRange">
                                    <h2 style="margin-bottom: 0px;" i18n><span
                                            style="color: #4382f0; ">{{roomService.myVote}}</span> points</h2>
                                    <h2 style="margin-top: 0px;" i18n>or <span style="color: #f42f2f; ">{{roomService.myVote
                                            * 2}}</span> sips for refusal</h2>
                                    <button (click)="roomService.submitMyVote(roomService.myVote)" i18n>Submit</button>
                                </div>
                                <div *ngIf="roomService.voted || overviewScreen">
                                    <div *ngFor="let player of roomService.playerList" style="font-size: 1.25rem;">
                                        <span *ngIf="player.id != roomService.theRoomWhereItHappens.player1">
                                            <span style="font-weight: 700;"> {{player.name}}: </span>
                                            <span *ngIf="player.vote" i18n><span style="color: #4382f0; ">{{player.vote}}</span>
                                            points or <span style="color: #f42f2f; ">{{player.vote * 2}}</span>
                                            sips</span>
                                        </span>
                                        <!-- {{roomService.theRoomWhereItHappens.players[roomService.player.id].voted}} -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="roomService.currentState == 'decision'">
                            <div *ngIf="roomService.player.id == roomService.theRoomWhereItHappens.player1">
                                <h2 i18n>Are you going to do it?</h2>
                                <button (click)="roomService.toDoOrNot('do')" i18n>Yes, I want
                                    {{roomService.getWorth('points')}} points</button>
                                <button style="margin-top: 15px;" (click)="roomService.toDoOrNot('not')" i18n>No, I rather
                                    drink {{roomService.getWorth('sips')}} sips</button>
                            </div>
                            <div *ngIf="roomService.player.id != roomService.theRoomWhereItHappens.player1">
                                <h2 i18n>
                                    {{roomService.getWorth('points')}} point or {{roomService.getWorth('sips')}} sips.
                                </h2>
                                <h3 i18n>Will
                                    {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player1].name}}
                                    do it?</h3>
                            </div>
                        </div>
                        <div *ngIf="roomService.currentState == 'action'">
                            <h2>
                                <span i18n
                                    *ngIf="roomService.theRoomWhereItHappens.player1_decision=='do'">{{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player1].name}}
                                    is doing it!!!</span>
                                <span i18n
                                    *ngIf="roomService.theRoomWhereItHappens.player1_decision=='not'">{{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player1].name}}
                                    rather drinks...</span>
                            </h2>
                            <div *ngIf="!overviewScreen">
                                <h3 i18n>Ready to continue?</h3>

                                <button (click)="next()" *ngIf="!roomService.theRoomWhereItHappens.players[roomService.player.id].next" i18n>Next!</button>
                                <div *ngIf="roomService.theRoomWhereItHappens.players[roomService.player.id].next"><strong>Waiting for more players to be ready!</strong></div>
    
                            </div>
                            <div *ngIf="overviewScreen">
                                <strong>Waiting for more players to be ready!</strong>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="roomService.currentGame.type =='double_dare'">
                        <h1>{{ getDoubleDareText() }}</h1>
                        <div *ngIf="roomService.currentState == 'input'">
                            <div
                                *ngIf="roomService.player.id == roomService.theRoomWhereItHappens.player1 || roomService.player.id == roomService.theRoomWhereItHappens.player2">
                                <h3 i18n="@@they-decide" i18n>They are deciding how many points or sips this is worth:</h3>
                                <div *ngFor="let player of roomService.playerList">
                                    <span *ngIf="player.id != roomService.theRoomWhereItHappens.player1 && player.id != roomService.theRoomWhereItHappens.player2">
                                        <span style="font-weight: 700;"> {{player.name}}: </span>
                                    <span *ngIf="player.vote" i18n>
                                        {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player1].name}}
                                        :{{player.vote.vote1}} points or {{player.vote.vote1 * 2}} sips |
                                        {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player2].name}}
                                        :{{player.vote.vote2}} points or {{player.vote.vote2 * 2}} sips
                                    </span>
                                    </span>
                                    <!-- {{roomService.theRoomWhereItHappens.players[roomService.player.id].voted}} -->
                                </div>
                            </div>
                            <div *ngIf="roomService.player.id != roomService.theRoomWhereItHappens.player1 && roomService.player.id != roomService.theRoomWhereItHappens.player2">
                            <div *ngIf="!roomService.voted && !overviewScreen">
                                <div style="display: flex; justify-content: center;">
                                    <div style="max-width: 220px; margin: 0 15px;">
                                        <h3 i18n>{{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player1].name}}:
                                        </h3>
                                        <div class="slidecontainer">
                                            <input [(ngModel)]="roomService.myVote" type="range" min="1" max="10"
                                                value="5" step="1" class="slider" id="myRange">
                                        </div>
                                        <h3 style="margin-bottom: 0px;" i18n><span
                                                style="color: #4382f0; ">{{roomService.myVote}}</span> points</h3>
                                        <h3 style="margin-top: 0px;" i18n>or <span
                                                style="color: #f42f2f; ">{{roomService.myVote * 2}}</span> sips for
                                            refusal</h3>
                                    </div>
                                    <div style="max-width: 220px; margin: 0 15px;">
                                        <h3>{{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player2].name}}
                                        </h3>
                                        <div class="slidercontainer">
                                            <input [(ngModel)]="roomService.myVote2" type="range" min="1" max="10"
                                                value="5" step="1" class="slider" id="myRange">
                                            <h3 style="margin-bottom: 0px;" i18n><span
                                                    style="color: #4382f0; ">{{roomService.myVote2}}</span> points</h3>
                                            <h3 style="margin-top: 0px;" i18n>or <span
                                                    style="color: #f42f2f; ">{{roomService.myVote2 * 2}}</span> sips for
                                                refusal</h3>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    (click)="roomService.submitMyVote({vote1: roomService.myVote, vote2: roomService.myVote2})" i18n>Submit</button>
                            </div>
                            <div *ngIf="roomService.voted || overviewScreen">
                                <h3 i18n>Waiting on the others:</h3>
                                <div *ngFor="let player of roomService.playerList">
                                    <span *ngIf="player.id != roomService.theRoomWhereItHappens.player1 && player.id != roomService.theRoomWhereItHappens.player2">
                                        <span style="font-weight: 700;"> {{player.name}}: </span>
                                    <span *ngIf="player.vote" i18n>
                                        {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player1].name}}
                                        :{{player.vote.vote1}} points or {{player.vote.vote1 * 2}} sips |
                                        {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player2].name}}
                                        :{{player.vote.vote2}} points or {{player.vote.vote2 * 2}} sips
                                    </span>
                                    </span>
                                    <!-- {{roomService.theRoomWhereItHappens.players[roomService.player.id].voted}} -->
                                </div>
                            </div>

                                <!-- <div class="slidecontainer"> -->

                                <!-- <input [(ngModel)]="roomService.myVote" type="range" min="1" max="10" value="5" step="1" class="slider" id="myRange"> -->
                                <!-- {{roomService.myVote}} -->




                                <!-- {{roomService.myVote2}} -->

                                <!-- </div> -->
                            </div>
                        </div>
                        <div *ngIf="roomService.currentState == 'decision'">
                            <div *ngIf="roomService.player.id == roomService.theRoomWhereItHappens.player1">
                                <div *ngIf="!roomService.decided">
                                    <h2 style="margin-bottom: 0px;" i18n>Will you do it?</h2>
                                    <span i18n>
                                        Remember if you refuse to do it and
                                        {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player2].name}}
                                        would do it you drink double and
                                        {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player2].name}}
                                        still gets the points
                                    </span>
                                    <br>
                                    <button style="margin-top: 15px;" (click)="roomService.toDoOrNot2('do', 1)" i18n>Yes I
                                        want {{roomService.getWorth2('points',1)}} points</button>
                                    <button style="margin-top: 15px;" (click)="roomService.toDoOrNot2('not', 1)" i18n>No I
                                        rather drink {{roomService.getWorth2('sips',1)}} sips</button>
                                </div>
                                <div *ngIf="roomService.decided">
                                    <h2 i18n> Waiting for
                                        {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player2].name}}
                                        to decide</h2>
                                </div>
                            </div>
                            <div *ngIf="roomService.player.id == roomService.theRoomWhereItHappens.player2">
                                <div *ngIf="!roomService.decided">
                                    <h2 style="margin-bottom: 0px;" i18n>Will you do it?</h2>
                                    <span i18n>
                                        Remember if you refuse to do it and
                                        {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player1].name}}
                                        would do it you drink double and
                                        {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player1].name}}
                                        still gets the points
                                    </span>
                                    <br>
                                    <button style="margin-top: 15px;" (click)="roomService.toDoOrNot2('do', 2)" i18n>Yes I
                                        want {{roomService.getWorth2('points',2)}} points</button>
                                    <button style="margin-top: 15px;" (click)="roomService.toDoOrNot2('not', 2)" i18n>No I
                                        rather drink {{roomService.getWorth2('sips',2)}} sips</button>
                                </div>
                                <div *ngIf="roomService.decided">
                                    <h2 i18n> Waiting for
                                        {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player2].name}}
                                        to decide</h2>
                                    <!-- {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player1].name}} -->
                                </div>
                            </div>
                            <div
                                *ngIf="roomService.player.id != roomService.theRoomWhereItHappens.player1 && roomService.player.id != roomService.theRoomWhereItHappens.player2">
                                <span i18n>
                                    <strong>{{roomService.getWorth2('points', 1)}} point or {{roomService.getWorth2('sips',
                                        1)}} sips. <br>
                                    </strong>
                                </span>
                                <span i18n>
                                    Will
                                {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player1].name}}
                                do it?
                                </span>
                                <br>
                                <br>
                               <span i18n>
                                <strong>{{roomService.getWorth2('points', 2)}} point or {{roomService.getWorth2('sips',
                                    2)}} sips.</strong> <br>
                                Will
                                {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player2].name}}
                                do it?
                               </span>
                            </div>
                        </div>
                        <div *ngIf="roomService.currentState == 'action'">
                            <span
                                *ngIf="roomService.theRoomWhereItHappens.player1_decision=='do' && roomService.theRoomWhereItHappens.player2_decision=='do'">
                                <h2 i18n>Both are doing it!!!</h2>
                            </span>
                            <span
                                *ngIf="roomService.theRoomWhereItHappens.player1_decision=='not' && roomService.theRoomWhereItHappens.player2_decision=='do'">
                                <strong i18n>{{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player2].name}}
                                    would have done it!</strong>
                                <h3 style="color: #f42f2f;" i18n>
                                    {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player1].name}}
                                    drink {{roomService.getWorth2('sips', 1) * 2}} sips</h3>
                            </span>
                            <span i18n
                                *ngIf="roomService.theRoomWhereItHappens.player1_decision=='do' && roomService.theRoomWhereItHappens.player2_decision=='not'">
                                <strong>{{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player1].name}}
                                    would have done it!</strong>

                                <h3 style="color: #f42f2f;">
                                    {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player2].name}}
                                    drink {{roomService.getWorth2('sips', 2) * 2}} sips</h3>
                            </span>
                            <span i18n
                                *ngIf="roomService.theRoomWhereItHappens.player1_decision=='not' && roomService.theRoomWhereItHappens.player2_decision=='not'">
                                <h3> They rather drink.
                                    {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player2].name}}:
                                    {{roomService.getWorth2('sips', 2)}} sips. And
                                    {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player1].name}}:
                                    {{roomService.getWorth2('sips', 1)}} sips.</h3>
                            </span>
                            <br>
                            <div *ngIf="!overviewScreen">
                                <span>
                                    <strong i18n>
                                        Ready to continue?
                                    </strong>
                                </span>
                                <br>
                                <button style="margin-top: 15px;" *ngIf="!roomService.theRoomWhereItHappens.players[roomService.player.id].next" (click)="next()" i18n>Next!</button>
                                <div *ngIf="roomService.theRoomWhereItHappens.players[roomService.player.id].next"><strong>Waiting for more players to be ready!</strong></div>
                            </div>
                            <div *ngIf="overviewScreen">
                                <strong>Waiting for more players to be ready!</strong>
                            </div>
                        </div>
                        
                    </div>
                    <div *ngIf="roomService.currentGame.type =='challenge'">
                        <h1>{{roomService.currentGame.body[roomService.locale] ? roomService.currentGame.body[roomService.locale] : roomService.currentGame.body.en}}</h1>
                        <h3>{{roomService.currentGame.subtitle[roomService.locale] ? roomService.currentGame.subtitle[roomService.locale] : roomService.currentGame.subtitle.en}}</h3>
                        <div *ngIf="roomService.currentState == 'input'">
                            <div *ngIf="!roomService.challengeDelay">
                                <h2 i18n>Who won/lost the challenge?</h2>
                                <div *ngFor="let player of roomService.playerList" class="playerOptions">
                                    <div><button
                                            (click)="roomService.challengeComplete(player.id)">{{player.name}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="roomService.currentState == 'decision'">
                            <h2 i18n> {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.challenge].name}}
                                lost/won the challenge
                            </h2>
                            <div *ngIf="!overviewScreen">
                                <button style="margin-top: 20px;" (click)="next()" *ngIf="!roomService.theRoomWhereItHappens.players[roomService.player.id].next" i18n>Next!</button>
                                <div *ngIf="roomService.theRoomWhereItHappens.players[roomService.player.id].next"><strong>Waiting for more players to be ready!</strong></div>    
                            </div>
                            <div *ngIf="overviewScreen">
                                <strong>Waiting for more players to be ready!</strong>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="roomService.currentGame.type =='punishment'">
                        <span *ngIf="roomService.player.id != roomService.theRoomWhereItHappens.player1">
                            <h2 i18n>{{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player1].name}}
                                gets to: </h2>
                        </span>
                        <h2>{{roomService.currentGame.body[roomService.locale] ? roomService.currentGame.body[roomService.locale] : roomService.currentGame.body.en}}</h2>
                        <div *ngIf="roomService.currentState == 'input'">
                            <div *ngIf="roomService.player.id != roomService.theRoomWhereItHappens.player1" i18n>
                                Who is going to be chosen?
                            </div>
                            <div *ngIf="roomService.player.id == roomService.theRoomWhereItHappens.player1"
                                class="playerOptions">
                                <div *ngFor="let player of roomService.playerList">
                                    <button *ngIf="player.id != roomService.player.id"
                                        (click)="roomService.punishPlayer(player.id)">{{player.name}}</button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="roomService.currentState == 'decision'">
                            <h1 style="color: #f42f2f;" i18n>
                                {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.player1].name}}
                                chose
                                {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.punish].name}}
                            </h1>
                            <div *ngIf="!overviewScreen">
                                <button style="margin-top: 20px;" (click)="next()" *ngIf="!roomService.theRoomWhereItHappens.players[roomService.player.id].next" i18n>Next!</button>
                                <div *ngIf="roomService.theRoomWhereItHappens.players[roomService.player.id].next"><strong>Waiting for more players to be ready!</strong></div>
                            </div>
                            <div *ngIf="overviewScreen">
                                <strong>Waiting for more players to be ready!</strong>
                            </div>
                        </div>
                    </div>


                    <div *ngIf="roomService.currentGame.type =='council'">
                        <h2>{{roomService.currentGame.body[roomService.locale] ? roomService.currentGame.body[roomService.locale] : roomService.currentGame.body.en}}</h2>
                        <div *ngIf="roomService.currentState == 'input'">
                            <div *ngIf="!roomService.voted">
                                <div class="playerOptions">
                                    <div *ngFor="let player of roomService.playerList">
                                        <button *ngIf="player.id != roomService.player.id"
                                            (click)="roomService.voteOnPlayer(player.id)">{{player.name}}</button>
                                    </div>
                                </div>

                            </div>
                            <div *ngIf="roomService.voted" class="playerVotes">
                                <div *ngFor="let player of roomService.playerList" class="vote">
                                    <div>{{player.name}}:</div>
                                    <div class="bubble" *ngIf="player.vote">
                                        {{roomService.theRoomWhereItHappens.players[player.vote].name}}</div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="roomService.currentState == 'decision'">

                            <div *ngIf="roomService.theRoomWhereItHappens.draw">
                                <h3 i18n>There was a draw between: <span
                                        *ngFor="let player_id of roomService.theRoomWhereItHappens.draw">{{roomService.theRoomWhereItHappens.players[player_id].name}}
                                    </span></h3>
                                <!-- <br> -->
                                <h3 i18n>The program selected randomly:
                                    {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.council].name}}
                                </h3>
                            </div>
                            <div *ngIf="!roomService.theRoomWhereItHappens.draw">
                                <h2 i18n> The council selected:
                                    {{roomService.theRoomWhereItHappens.players[roomService.theRoomWhereItHappens.council].name}}
                                </h2>

                            </div>
                            <h2>{{roomService.currentGame.subtitle[roomService.locale] ? roomService.currentGame.subtitle[roomService.locale] : roomService.currentGame.subtitle.en}}</h2>
                            <div *ngIf="!overviewScreen">
                                <button (click)="next()" *ngIf="!roomService.theRoomWhereItHappens.players[roomService.player.id].next" i18n>Next!</button>
                                <div *ngIf="roomService.theRoomWhereItHappens.players[roomService.player.id].next"><strong>Waiting for more players to be ready!</strong></div>    
                            </div>
                            <div *ngIf="overviewScreen">
                                <strong>Waiting for more players to be ready!</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 40px;" *ngIf="!overviewScreen">
                    <button style="transform: scale(0.7);" (click)="addCustomGame()">Add custom game</button>
                </div>
            </div>
            <div *ngIf="pointsView || overviewScreen" style="width: 100%;">
                <h1 i18n>Scores so far:</h1>
                <div class="playerWrapper">
                    <div class="player" *ngFor="let player of roomService.playerList">
                        {{player.name}}: {{player.points}}
                    </div>
                </div>
                <h3>Rounds played: {{roomService.theRoomWhereItHappens?.rounds_played}}</h3>
            </div>
            <!-- <div *ngIf="">

            </div> -->
            <div *ngIf="addingGameScreen">
                <app-add-custom></app-add-custom>
            </div>
        </div>
    </div>
</div>
<div *ngIf="roomService.showOverlay" class="wrapper bg overlay">
    <div class="overlayContent">
        <!-- <button *ngIf="!showAppear" (click)="test()">Testeroo</button>
        <div *ngIf="showAppear"> -->
        <div *ngIf="!roomService.announce" id="wheel">
            <img class="wheel spinning" src="./assets/images/wheel02.svg" alt="">
        </div>
        <div *ngIf="roomService.announce" id="announcement">
            <img *ngIf="roomService.currentGameType =='dare'" class="announcement appearing"
                src="./assets/images/games/dare.svg" alt="">
            <img *ngIf="roomService.currentGameType =='punishment'" class="announcement appearing"
                src="./assets/images/games/punishment.svg" alt="">
            <img *ngIf="roomService.currentGameType =='double_dare'" class="announcement appearing"
                src="./assets/images/games/double_dare.svg" alt="">
            <img *ngIf="roomService.currentGameType =='truth'" class="announcement appearing"
                src="./assets/images/games/truth.svg" alt="">
            <img *ngIf="roomService.currentGameType =='council'" class="announcement appearing"
                src="./assets/images/games/council.svg" alt="">
            <img *ngIf="roomService.currentGameType =='challenge'" class="announcement appearing"
                src="./assets/images/games/challenge.svg" alt="">
        </div>
        <!-- </div> -->
    </div>
</div>