<div>
    <div>
        
        <br><br>
        <!-- <select [(ngModel)]="inputType" name="" id="">
            <option value="dare">Dare</option>
            <option value="truth">Truth</option>
            <option value="double_dare">Double Dare</option>
            <option value="punishment">Punishment</option>
            <option value="council">Council</option>
            <option value="challenge">Challenge</option>
        </select>
        {{inputType}} -->
        <br>
        <input [(ngModel)]="inputString" type="text">
        <button (click)="parseStringToGames()">Parse</button>

        <div *ngIf="addGamesService.newGames">
            <div *ngFor="let game of addGamesService.newGames">
                <div>
                    {{game.body.en}}
                </div>
            </div>
        </div>
        <button (click)="addGamesService.saveNewGames()">Add Games</button>
        <button (click)="addGamesService.updateOverview()">Update</button>
    </div>
</div>